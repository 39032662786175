import _classCallCheck from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/classCallCheck";
import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";

/* eslint-disable no-console */
import getEnvFromHost from '@buildablecore/src/logic/utils/getEnvFromHost';
import { noop } from './ErrorHandler';
export var Logger = // eslint-disable-next-line no-useless-constructor
function Logger(log, info, warn, error, debug, trace) {
  _classCallCheck(this, Logger);

  this.log = log;
  this.info = info;
  this.warn = warn;
  this.error = error;
  this.debug = debug;
  this.trace = trace;
};

_defineProperty(Logger, "make", function (_ref) {
  var log = _ref.log,
      info = _ref.info,
      warn = _ref.warn,
      error = _ref.error,
      debug = _ref.debug,
      trace = _ref.trace;
  var env = getEnvFromHost();

  if (['beta', 'production'].includes(env)) {
    return new Logger(noop, noop, noop, noop, noop, noop);
  }

  return new Logger(log, info, warn, error, debug, trace);
});

export var logger = Logger.make({
  error: console.error,
  log: console.log,
  info: console.info,
  warn: console.warn,
  debug: console.debug,
  trace: console.trace
});