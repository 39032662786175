import { useQuery } from 'react-query';
import reactQueryKeys from '../queryKeys';
import { apiWithToken } from '../utils/dispatchRecipe';
import { apiKeys } from '../utils/apiKeys';



const useListEnvVars = (params = {retry: false, refetchOnWindowFocus: true, staleTime: 1000}) => useQuery({
  queryKey: [reactQueryKeys.envVarsList],
  queryFn: () =>
    apiWithToken({
      url: apiKeys['list.environment.variables'],
      method: 'POST',
      payload: {
        "fields": ["envsArray"]
      }
    }),
    ...params
})

export default useListEnvVars
