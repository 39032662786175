import { AddIcon, CheckIcon } from '@chakra-ui/icons';
import { HStack } from '@chakra-ui/layout';
import {
  Box,
  Button,
  ButtonGroup,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { ReactNode, useState } from 'react';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { VscSymbolVariable } from 'react-icons/vsc';
import TYPES from '../../../config/types';
import { calculateTimeBetween } from '../../../helpers/date';
import useCreateEnvVarInteraction from '../../../logic/interactions/useCreateEnvVarInteraction';
import useEditEnvVarInteraction from '../../../logic/interactions/useUpdateEnvVarInteraction';
import useStore from '../../../logic/zustand';
import EnvVarForm from '../forms/EnvVarForm';
import SingleKeyLabel from './SingleKeyLabel';

export const BaseItem = ({
  children,
  development,
  name,
  production,
  w,
  setEditing,
  editing,
  showVariables,
}: {
  children: ReactNode;
  development: string;
  name: string;
  production: string;
  w: string | number;
  showVariables: boolean;
  setEditing: (editing: boolean) => void;
  editing: boolean;
}) => {
  const bgColor = useColorModeValue('navy.100', 'navy.900');
  const borderColor = useColorModeValue('gray.300', 'gray.700');
  const { updateEnvVar, isUpdatingEnvVar } = useEditEnvVarInteraction();
  const { createEnvVar, isLoadingCreateEnvVar } = useCreateEnvVarInteraction();
  const handleAddEnvVar = async (params) => {
    await createEnvVar(params);
    setEditing(false);
  };

  const { openModal, setSelect } = useStore((store) => ({
    openModal: store['modal/open'],
    setSelect: store.setSelect,
  }));

  const handleDelete = () => {
    setSelect(TYPES.SELECT.ENV_VAR_NAME, name);
    openModal({ type: TYPES.MODALS.DELETE_ENV_VAR });
  };

  return (
    <VStack
      w="100%"
      p="5"
      align="start"
      borderColor={borderColor}
      spacing="4"
      borderBottomWidth={w}
      _last={{
        border: 'none',
      }}
      _hover={{
        bg: bgColor,
      }}
    >
      <HStack justify="space-between" w="100%">
        <HStack spacing="4">
          <Box
            borderColor={borderColor}
            borderWidth="1px"
            p="2"
            borderRadius="100%"
          >
            <VscSymbolVariable
              size="18px"
              style={{ color: 'var(--chakra-colors-gray-500)' }}
            />
          </Box>
          <Text fontWeight="600">{name}</Text>
        </HStack>
        {children}
      </HStack>
      {showVariables && (
        <HStack spacing="4" justify="space-between" w="100%" align="stretch">
          <SingleKeyLabel label="DEVELOPMENT VALUE" value={development} />
          <SingleKeyLabel label="PRODUCTION VALUE" value={production} />
          <Menu>
            <MenuButton
              px={4}
              py={2}
              transition="all 0.2s"
              borderRadius="md"
              variant="ghost"
            >
              <BiDotsVerticalRounded
                style={{
                  fontSize: '20px',
                }}
              />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => setEditing(!editing)}>Edit</MenuItem>
              <MenuItem onClick={handleDelete} color="red.500">
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      )}
      {editing && (
        <EnvVarForm
          values={{
            name,
            development,
            production,
          }}
          ///The editing and isTitleReadOnly is stricly bounded to the showVariables prop,
          /// since we can't show values if we are adding them for the first time
          isTitleReadOnly={!showVariables}
          editing={showVariables}
          onEdit={updateEnvVar}
          onAdd={handleAddEnvVar}
          isEditing={isUpdatingEnvVar || isLoadingCreateEnvVar}
          onCancel={() => setEditing(false)}
        />
      )}
    </VStack>
  );
};

const EnvVarItem = ({
  addedAt,
  developmentValue: development,
  name,
  productionValue: production,
}: {
  addedAt: number;
  developmentValue: string;
  name: string;
  productionValue: string;
}) => {
  const [editing, setEditing] = useState(false);

  return (
    <BaseItem
      name={name}
      development={development}
      production={production}
      w="1px"
      showVariables
      setEditing={setEditing}
      editing={editing}
    >
      <Text color="gray.600" fontSize="sm">
        Added {calculateTimeBetween({ source: addedAt })}
      </Text>
    </BaseItem>
  );
};

export default EnvVarItem;

export const CanvasEnvVarItem = ({
  development,
  name,
  production,
  fullfilled,
  onClick,
}: {
  development: string;
  name: string;
  production: string;
  fullfilled: boolean;
  onClick: () => void;
}) => {
  const [editing, setEditing] = useState(false);
  return (
    <BaseItem
      name={name}
      development={development}
      production={production}
      w={0}
      showVariables={false}
      setEditing={setEditing}
      editing={editing}
    >
      <ButtonGroup size="sm" isAttached variant="outline" colorScheme="red">
        {fullfilled ? (
          <Tag size="md" key="md" variant="subtle" colorScheme="green">
            <TagLeftIcon boxSize="9px" as={CheckIcon} />
            <TagLabel fontSize="sm">Added</TagLabel>
          </Tag>
        ) : (
          <Button
            onClick={() => {
              onClick();
              setEditing(!editing);
            }}
            leftIcon={!editing ? <AddIcon fontSize="9px" /> : null}
            size="sm"
            key="md"
            variant="solid"
            colorScheme={!editing ? 'blue' : 'gray'}
          >
            {!editing ? 'Add new' : 'Cancel'}
          </Button>
        )}
      </ButtonGroup>
    </BaseItem>
  );
};
