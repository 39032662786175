/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FormControl,
  FormErrorMessage,
  FormLabel, Input,
  Select
} from '@chakra-ui/react';
import { FC } from 'react';
import { IOptions, IRules } from '../shared/interface';

interface IProps {
  name: string
  label: string
  rules?: IRules
  placeholder?: string
  options: IOptions[]
  errors?: any
  register?: any
  disabled?: boolean
}

const FormSelect: FC<IProps> = ({
  name,
  label,
  rules,
  placeholder,
  options,
  errors,
  register,
  disabled
}) => (
    <FormControl isInvalid={errors[name]}>
      <FormLabel fontWeight="bold" htmlFor={name}>{label}</FormLabel>
       <Select
         disabled={disabled}
         placeholder={placeholder}
         variant="outline"
       >
         {options.map(({ name: _name, value }) => (
           <option value={value}>{_name}</option>
         ))}
       </Select>
      <Input value={options?.[0]?.value} hidden placeholder={placeholder} {...register(name)} />
      <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
    </FormControl>
  );

export default FormSelect;
