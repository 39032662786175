const TYPES = {
  SELECT: {
    MICROSERVICE: 'microservice',
    MICROSERVICE_PACKAGE_OPTION: 'microservice-package-option',
    CONNECTION_FEEDBACK: 'connection-feedback',
    CONNECTION_CREATE_PROCESSING_MESSAGE:
      'connection-create-processing-message',
    MICROSERVICE_NAME: 'microservice-name',
    SETTINGS: 'settings',
    SNIPPET_ARGS: 'snippet-args',
    RECIPE_RECORD: 'recipe-record',
    MICROSERVICE_RECORD: 'microservice-record',
    RELATION_ID: 'relation-id',
    APPEND: 'append',
    RELATION_DELETE_TRIGGER_ID: 'relation-delete-trigger-id',
    RELATION_NAME: 'relation-name',
    ENVIRONMENT_STATE: 'environment-state',
    ENV_VAR_NAME: 'envvar-name',
  },
  MODALS: {
    JOIN_WAITLIST: 'BuildableJoinWaitlistModal',
    CONNECT_TO_MICRO_SERVICE_STEP_ONE: 'ConnectToMicroServiceStepOne',
    CONNECT_TO_MICRO_SERVICE_STEP_TWO: 'ConnectToMicroServiceStepTwo',
    DELETE_RECIPE: 'DeleteRecipe',
    DELETE_MICROSERVICE: 'DeleteMicroservice',
    DELETE_RELATION: 'DeleteRelation',
    CREATE_FLOW: 'CreateFlow',
    DELETE_ENV_VAR: 'DeleteEnvVar',
  },
  USER_INPUTS: {
    SEARCH_TERM: 'search-term',
  },
  ROUTES: {
    RECIPES: '/flows',
    SETTINGS: '/settings',
    PROFILE: '/settings/profile',
    PREFERENCES: '/settings/preferences',
    ENV_VARS: '/settings/environment-variables',
    SEARCH: '/search',
    HOME: '/home',
    MICROSERVICES: '/datasources',
    MICROSERVICE_DETAILS: '/datasources/[id]',
    MICROSERVICE_SERVICE_QUERY: '/datasources/[id]/[service]',
    LOGS: '/logs',
    404: '/404',
  },
  PATHNAME: {
    MICROSERVICE: 'datasources'
  },
  WAITING: {
    PROCESSING: {
      CREATE_TEST_CONNECTION: 'create-test-connection',
      CREATE_CONNECTION: 'create-connection',
      UPDATING_USER: 'updating-user',
      UPDATING_USER_PASSWORD: 'updating-user-password',
      JOIN_WAITLIST: 'join-waitlist',
      DELETE_RECIPE: 'delete-recipe',
      DELETE_MICROSERVICE: 'delete-microservice',
      DELETE_RELATION: 'delete-relation',
    },
  },
  FORM_ELEMENTS: {
    INPUT: 'input',
    BUTTON: 'button',
    NUMBER: 'number',
    PASSWORD: 'password',
    RADIO: 'radio',
    SELECT: 'select',
    TEXTAREA: 'textarea',
    SWITCH: 'switch',
    CHECKBOX: 'checkbox',
  },
  MICROSERVICE_INSTANCE_CREATION_STATUS: {
    CONNECTING: 'Connecting',
    PENDING: 'Pending',
    CREATED: 'CREATED',
  },
  SETTINGS_TYPES: {
    PROFILE: 'profile',
    PREFERENCES: 'preferences',
    INVITE: 'invite',
    ENV_VARS: 'environment-variables',
  },

  IS_TOGGLE_ENABLED: false,

  DOCS: {
    ENVIRONMENT_VARIABLES:
      'https://docs.buildable.dev/features/environment-variables',
    NPM_PACKAGES: 'https://docs.buildable.dev/resources/npm-packages',
    ADD_NODES:
      'https://docs.buildable.dev/core-products/flows/add-nodes-to-a-flow',
  },

  ENVIRONMENT_VARIABLES: {
    TEST: 'test',
    LIVE: 'live',
  },
};

export default TYPES;
