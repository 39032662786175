import { getCookie, parseJwt } from '../../helpers/utility';
import getEnvFromHost from '../utils/getEnvFromHost';

const env = getEnvFromHost() === 'production' ? 'live' : 'test';
const baseurl = `https://api.buildable.dev/flow/v1/call/${env}/segment-rest-37e717ebca/`;

export default function useTracking() {
  const cookie = getCookie();
  const parsedToken = parseJwt(cookie?.authKey) || {};

  function makeContext() {
    let context = {};
    try {
      const {
        location: { pathname: path = '', search = '', href: url = '' } = {},
      } = window || {};

      context = {
        locale: navigator?.language,
        page: {
          path,
          search,
          title: document?.title,
          url,
        },
        userAgent: navigator?.userAgent,
      };
    } catch (ex) {
      // just eat the error. this happens if an
      // event is fired server side inadvertently
    }
    return context;
  }

  function request(path, data) {
    fetch(`${baseurl}${path}`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(data),
    });
  }

  function track(event, properties = {}) {
    request('t', {
      event,
      userId: parsedToken._id,
      properties,
      context: makeContext(),
    });
  }

  function identify() {
    request('i', {
      userId: parsedToken._id,
      traits: {
        name: `${parsedToken.firstName} ${parsedToken.lastName}`,
      },
      context: makeContext(),
    });
  }

  function page() {
    // Temporary until flow is updated
    track('Page Loaded');

    // Use this when flow is updated
    // request('p', {
    //   userId: parsedToken._id,
    //   context: makeContext(),
    // });
  }

  return {
    track,
    identify,
    page
  };
}
