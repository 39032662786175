import { Box, Stack } from '@chakra-ui/react';
import * as React from 'react';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { MdCode } from 'react-icons/md';
import { BsPlayBtn } from 'react-icons/bs';
import { FaFeatherAlt } from 'react-icons/fa';
import useTracking from '../../../logic/hooks/useTracking';
import trackingConsts from '../../../config/tracking';
import Link from '../../../../../shared-components/src/lib/Link';

interface WelcomeCardProps {
  imageUrl: string;
  title: string;
  children: React.ReactNode;
  iconBgColor: string;
  href: string;
  icon: string;
}

const icons = {
  IoDocumentTextOutline,
  MdCode,
  BsPlayBtn,
  FaFeatherAlt
};

const CustomIcon = ({ icon, ...rest }) => {
  const Icon = icons[icon];
  return <Icon {...rest} />;
};

export const WelcomeCard = (props: WelcomeCardProps) => {
  const { title, children, imageUrl, iconBgColor, href, icon } = props;
  const { track } = useTracking();

  const handleClick = () => {
    track(trackingConsts.HOME.CLICKED_CARD, { title, href });
  };

  return (
    <Link href={href}>
      <Box
        onClick={handleClick}
        bg="gray.800"
        cursor="pointer"
        mx="auto"
        w="100%"
        rounded={{ lg: 'lg' }}
        p="8"
        shadow="base"
        overflow="hidden"
      >
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          spacing="5"
          justify="space-between"
        >
          <Stack direction={{ base: 'column', sm: 'row' }} spacing="8" flex="1">
            <Box
              p="2"
              rounded="md"
              bg={iconBgColor}
              alignSelf="center"
              aria-hidden
            >
              <CustomIcon icon={icon} fontSize="30px" size="30px" />
            </Box>
            <Box textAlign={{ base: 'center', md: 'start' }} flex="1">
              <Box as="h4" fontWeight="bold">
                <span>{title}</span>
              </Box>
              <Box color="gray.200" fontSize="md">
                {children}
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Link>
  );
};
