import { Button, useColorModeValue as mode, Text } from '@chakra-ui/react';
import { IoCreateOutline } from 'react-icons/io5';
import * as FullStory from '@fullstory/browser';
import { useEffect } from 'react';
import { TYPES } from '../../index';
import useCreateRecipeFlow from '../../logic/flows/useCreateRecipe';
import useGetUserFlow from '../../logic/flows/useGetUser';
import useStore from '../../logic/zustand';
import useTracking from '../../logic/hooks/useTracking';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics: any;
  }
}

const CreateRecipeButton = ({ onClick }) => {
  const { data } = useGetUserFlow();
  const { identify } = useTracking();

  const { openModal } = useStore((store) => ({
    openModal: store['modal/open'],
  }));

  // TODO: this should be handled in the app, not a shared component
  useEffect(() => {
    try {
      if (data?._id) {
        identify();
        FullStory.identify(data?._id, {
          displayName: `${data?.firstName} ${data?.lastName}`,
          email: data?.email,
          // TODO: Add your own custom user variables here, details at
          // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-Recording-custom-user-data
          reviewsWritten_int: 14,
        });
      }
    } catch (error) {
      console.log('Error in Fullstory identify');
    }
  }, [data?._id, data?.email, data?.firstName, data?.lastName]);

  const handleClick = () => {
    onClick?.();
    openModal({ type: TYPES.MODALS.CREATE_FLOW });
  };

  return (
    <Button
      onClick={handleClick}
      borderWidth="1px"
      bgColor={mode('white', 'gray.800')}
      borderColor={mode('#F0F3F9', 'gray.700')}
      color={mode('gray.800', 'white')}
      _hover={{
        bgColor: mode('#F0F3F9', 'gray.700'),
      }}
      leftIcon={<IoCreateOutline />}
      justifyContent="start"
      colorScheme="gray"
      fontWeight="normal"
      // variant='outline'
    >
      <Text>Create a flow</Text>
    </Button>
  );
};

export default CreateRecipeButton;
