import { Flex } from '@chakra-ui/layout';

interface StackWithHeaderAndFooterProps {
  children: React.ReactNode;
  headerSlot?: React.ReactNode;
  footerSlot?: React.ReactNode;
  px?: string;
  py?: string
}

export default function StackWithHeaderAndFooter({
  children,
  headerSlot,
  footerSlot,
  px,
  py
}: StackWithHeaderAndFooterProps) {
  return (
    <Flex
      py={py}
      px={px}
      bg="black"
      h="full"
      w="100%"
      direction="column"
      justify="space-between"
    >
      {headerSlot}
      <Flex flex="1" direction="column">
        {children}
      </Flex>
      {footerSlot}
    </Flex>
  );
}

StackWithHeaderAndFooter.defaultProps = {
  headerSlot: <></>,
  footerSlot: <></>,
  px: "3",
  py: "5"
};
