import { useQuery } from 'react-query';
import { models } from '../queries';
import reactQueryKeys from '../queryKeys';
import dispatchRecipe from '../utils/dispatchRecipe';

const useGetUserFlow = () => {
  const { data, status } =  useQuery (
    [reactQueryKeys.user],
    () =>
      dispatchRecipe({
        triggerId: models.users.actions.get,
      }),
    { retry: false, refetchOnWindowFocus: false, staleTime: 5 * 60 * 1000, }
  );

  return { data, status };
};

export default useGetUserFlow;
