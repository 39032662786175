import { ChangeEvent, useState } from 'react';

export default function useForms<
  T extends {
    [key: string]: string | number | boolean | undefined;
  }
>(initData: T) {
  const [formData, setFormData] = useState<T>({ ...initData });

  function onChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) {
    const { target } = e;
    const { name } = target;
    let { value } = target;

    if (target.type === 'select-one') {
      value = (target as any).selectedOptions[0]?.value;
    }

    setFormData((current) => ({ ...current, [name]: value }));
  }

  function setData(name: string, value: unknown) {
    // todo: validations
    setFormData((current) => ({
      ...current,
      [name]: value,
    }));
  }

  function resetData() {
    setFormData({ ...initData });
  }

  return {
    formData,
    onChange,
    setData,
    resetData,
  };
}
