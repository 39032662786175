/* eslint-disable @typescript-eslint/no-explicit-any */
import create from 'zustand';
import shallow from 'zustand/shallow';
import { useCallback, useEffect } from 'react';

import { devtools } from 'zustand/middleware';
import initModalModel from './initModalModel';
import initSetterModel from './initSetterModal';

import ModalComponents from '../../lib/modal';
import TYPES from '../../config/types';

const GLOBAL_KEY = '__global__';

const useStore = create<any>(
  devtools((set, get) => ({
    ...initSetterModel(set, get, 'select', []),
    ...initSetterModel(set, get, 'waiting', []),
    ...initSetterModel(set, get, 'userInputs', []),
    ...initModalModel(set, get, ModalComponents, TYPES.MODALS),
    setGlobal: (key, value) =>
      set(
        (state) => ({
          ...state,
          [`${GLOBAL_KEY}.${key}`]: value,
        }),
        false,
        `global.set.${key}`
      ),
  }))
);

export default useStore;

interface UseGlobalConfig {
  persist?: boolean;
  ttl?: number;
}

type Setter = <T>(T, config?: UseGlobalConfig) => void;

const globalSelector = key => (store) => ({
  setGlobal: store.setGlobal,
  value: store[`${GLOBAL_KEY}.${key}`],
});

/**
 *
 * Usage:
 * const [value, setValue] = useGlobal("my-key-name"); // value is the current value of the key
 *
 * Works exactly like useState, but for global state.
 *
 * Behind the scenes, it creates a new state tree for the global state, with a key of __global__.YOUR_KEY_NAME.
 *
 */

export const useGlobal = <T>(key: string): [T, Setter] => {
  const { setGlobal, value } = useStore<{ setGlobal: any; value: T }>(
    globalSelector(key),
    shallow
  );

  useEffect(() => {
    const localStorageValue = localStorage.getItem(`${GLOBAL_KEY}.${key}`);
    let parsedValue;
    try {
      parsedValue = JSON.parse(localStorageValue);
    } catch (e) {
      parsedValue = null;
    }
    if (parsedValue?.ttl > Date.now()) {
      setGlobal(key, parsedValue.value);
    } else {
      localStorage.removeItem(`${GLOBAL_KEY}.${key}`);
    }
  }, []);

  const set = useCallback(
    (_value, config?: UseGlobalConfig) => {
      setGlobal(key, _value);
      if (config?.persist) {
        setTimeout(() => {
          localStorage.setItem(
            `${GLOBAL_KEY}.${key}`,
            JSON.stringify({
              value: _value,
              ttl: Date.now() + (config.ttl || 30 * 1000),
            })
          );
        }, 0);
      } else {
        setTimeout(() => { // something should listen for these changes or you will always be out of sync until refresh.
          localStorage.removeItem(`${GLOBAL_KEY}.${key}`);
        }, 0);
      }
    },
    [key]
  );

  return [value, set];
};
