import getEnvFromHost from '../logic/utils/getEnvFromHost';

const hostList = {
  localhost: 'localhost',
  development: 'localhost',
  staging: 'buildable.io',
  beta: 'buildable.dev',
  production: 'buildable.dev',
};

const env = getEnvFromHost();

function getDomain(subdomain) {
  const domain = 'buildable';
  const tld = ['beta', 'production'].includes(env) ? 'dev' : 'io';
  let sd = subdomain;
  if (['staging', 'beta'].includes(env)) {
    sd = `${env}-${subdomain}`;
  } else if (env !== 'production') {
    sd = `development-${subdomain}`;
  }

  return `https://${sd}.${domain}.${tld}`;
}

const host = hostList[env];

const welcomeUrl =
  ['development', 'localhost'].includes(env)  ? 'http://localhost:4201' : getDomain('welcome');

const builderUrl =
  ['development', 'localhost'].includes(env)? 'http://localhost:4200' : getDomain('canvas');

export const microservicesUrl =
  ['development', 'localhost'].includes(env) ? 'http://localhost:4502' : getDomain('app');

export const flowSecureCallerEndpoint = `${getDomain(
  'api'
)}/secure-flow/v1/call/`;
export const flowCallerEndpoint = `${getDomain('api')}/flow/v1/call/`;

export default {
  cookies: {
    name: 'buildable',
    visitor: 'buildable',
    expiry: 200,
    domain: host,
  },
  microservicesUrl,
  welcomeUrl,
  builderUrl,
  activeEnv: env,
  host,
};
