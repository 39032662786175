import { useToast } from '@chakra-ui/react';
import { TYPES } from '../../index';
import { useCreateFlow } from '../hooks/useCreateFlow';
import useStore from '../zustand'
import config from '../../config'
import useTracking from '../hooks/useTracking';
import trackingConsts from '../../config/tracking';

function useCreateFlowInteraction () {
  const toast = useToast();
  const { track } = useTracking();
  const { closeModal } = useStore((store) => ({
    closeModal: store['modal/close']
  }))
  const { createFlow, error, isLoading } = useCreateFlow();
  const create = async (payload) => {
    try {
      const data = await createFlow(payload);
      window.location.href=`${config.builderUrl}/board/${data?._id}`;
      track(trackingConsts.DASHBOARD_SIDEBAR.CREATED_FLOW, data);
    } catch (e) {
      const description = e?.response?.data?.message || 'A new flow could not be created.';
      toast({
        title: "Some error occurred",
        description,
        status: "error",
        duration: 5000,
        position: 'top',
        isClosable: true,
      })
      track(trackingConsts.DASHBOARD_SIDEBAR.CREATED_FLOW, {error: description})
    } finally {
      closeModal({type: TYPES.MODALS.CREATE_FLOW})
    }
  }

  return { create, error, isLoading };
}

export default useCreateFlowInteraction
