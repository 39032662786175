import { useToast } from '@chakra-ui/react';
import trackingConsts from '../../config/tracking';
import TYPES from '../../config/types';
import { useDeleteEnvVar } from '../hooks/useDeleteEnvVar';
import useTracking from '../hooks/useTracking';
import useStore from '../zustand';

function useDeleteEnvVarInteraction() {
  const toast = useToast();
  const { track } = useTracking();
  const { deleteEnv, isLoading } = useDeleteEnvVar();
  const { closeModal, setSelect } = useStore((store) => ({
    closeModal: store['modal/close'],
    setSelect: store.setSelect
  }))
  const deleteEnvVar = async (payload) => {
    try {
      const resp = await deleteEnv(payload)
      toast({
        title: 'Environment variable deleted successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top'
      });
      track(trackingConsts.SETTINGS.CONFIRMED_DELETE_ENVIRONMENT_VARIABLE, resp)
    } catch (error) {
      const description = error?.response?.data?.message || "Environment variable could not be deleted"
      toast({
        title: 'Some error occurred',
        description,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top'
      });
      track(trackingConsts.SETTINGS.CONFIRMED_DELETE_ENVIRONMENT_VARIABLE, {error: description})
    } finally {
      closeModal({ type: TYPES.MODALS.DELETE_ENV_VAR });
      setSelect(TYPES.SELECT.ENV_VAR_NAME, false);
    }
  }

  return { deleteEnvVar, isLoading }

}

export default useDeleteEnvVarInteraction;
