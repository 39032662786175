/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Divider, Flex, HStack, Text } from '@chakra-ui/layout';
import { Tag } from '@chakra-ui/react';
import { format } from 'date-fns';
import dynamic from 'next/dynamic';
import Drawer from '../../../../shared-components/src/lib/Drawer';
import HeaderWithTag from '../../../../shared-components/src/lib/Headers/HeaderWithTag';
import DrawerSkeleton from '../../../../shared-components/src/lib/Skeletons/Drawer';
import { useGetLogEventsBySession } from '../../logic/hooks/flowLogs/hooks';
import { useDrawerInteraction } from '../../logic/hooks/flowLogs/interactions';
import { GLOBAL_KEYS } from '../../logic/hooks/GLOBAL_KEYS';

const DynamicReactJson = dynamic(import('react-json-view'), { ssr: false });

function DrawerBody({ data }) {
  return (
    <Flex overflowY="auto" w="100%" direction="column">
      <Divider mb="4" />
      {data?.events?.map((event) => {
        const inputOutput = JSON.parse(event?.output || event?.input);
        const isObject = typeof inputOutput === 'object';
        return (
          <Flex
            key={event?.event?.name}
            borderBottomWidth="1px"
            mb="4"
            pb="4"
            px="8"
            direction="column"
            borderColor="gray.800"
          >
            <HStack w="100%" justify="space-between">
              <HStack>
                <Tag size="sm">{event?.output ? 'Output' : 'Input'}</Tag>
                <Text fontSize="sm">
                  {event?.event?.name?.split('.')?.slice(1).join('.')}
                </Text>
              </HStack>
              <Text color="gray.400" fontSize="xs">
                {format(event?.eventAt, 'LLLL do, yyyy KK:mm:ss.SS a')}
              </Text>
            </HStack>
            {isObject && (
              <DynamicReactJson
                name={false}
                theme="ocean"
                collapsed={1}
                style={{ width: '100%', backgroundColor: ' transparent' }}
                src={inputOutput}
              />
            )}
            {!isObject && <Text fontSize="sm">{inputOutput}</Text>}
          </Flex>
        );
      })}
    </Flex>
  );
}

export default function ControlledDrawer() {
  const { isOpen, close } = useDrawerInteraction(GLOBAL_KEYS.DRAWERS.FLOW_LOGS);
  const { data, isLoading, ...rest } = useGetLogEventsBySession();
  return (
    <Drawer
      headerSlot={
        <HeaderWithTag
          isLoading={isLoading}
          heading={data?.flowSlug}
          tag={data?.session}
        />
      }
      isOpen={isOpen}
      onClose={close}
    >
      {isLoading && <DrawerSkeleton />}
      {!isLoading && <DrawerBody data={data} />}
    </Drawer>
  );
}
