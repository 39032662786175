/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IObject, IRules } from '../shared/interface';

interface IProps {
  name: string
  placeholder?: string
  label?: string
  rules?: IRules
  errors?: any
  register?: any,
  autocomplete?: string
}

const FormInput: FC<IProps> = ({ name, placeholder, label, rules, errors, register, autocomplete }) => (
    <FormControl isInvalid={errors[name]}>
      <FormLabel fontWeight="bold" htmlFor={label}>{label}</FormLabel>
      <Input placeholder={placeholder} autoComplete={autocomplete} {...register(name, rules)} />
      <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
    </FormControl>
  );

export default FormInput;
