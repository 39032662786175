import BuildableJoinWaitlistModal from './JoinWaitList';
import ConnectToMicroServiceStepOne from './ConnectToMicroServiceStepOne';
import ConnectToMicroServiceStepTwo from './ConnectToMicroServiceStepTwo';
import DeleteRecipe from './DeleteRecipe';
import DeleteMicroservice from './DeleteMicroservice';
import DeleteRelation from './DeleteRelation';
import CreateFlow from './CreateFlow';
import DeleteEnvVar from './DeleteEnvVar';

export default {
  BuildableJoinWaitlistModal,
  ConnectToMicroServiceStepOne,
  ConnectToMicroServiceStepTwo,
  DeleteRecipe,
  DeleteMicroservice,
  DeleteRelation,
  CreateFlow,
  DeleteEnvVar,
};
