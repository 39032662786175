import TYPES from "../../config/types";
import { useCreate } from "../queries";
import useStore from "../zustand";
import { apiWithToken } from '../utils/dispatchRecipe';
import { apiKeys } from '../utils/apiKeys'
import useTracking from "../hooks/useTracking";
import trackingConsts from "../../config/tracking";

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Credentials {
    [k: string]: any;
}

interface IProps {
    triggerId: string
}

const useCreateTestConnectionFlow = (props: IProps) => {
    const { triggerId } = props;
    const {setWaiting, clearWaiting, setSelect} = useStore((store) => ({
        setWaiting: store.setWaiting,
        clearWaiting: store.clearWaiting,
        setSelect: store.setSelect
    }));
    const { track } = useTracking();
    const createTestConnection = (payload) => apiWithToken({
        url: apiKeys['test-connection.microservice'],
        method: 'POST',
        payload
      })
    const createTestConnectionFlow = async (credentials: Credentials) => {
        try {
            setWaiting(TYPES.WAITING.PROCESSING.CREATE_TEST_CONNECTION, true);
            const response = await createTestConnection(credentials);
            setSelect(TYPES.SELECT.CONNECTION_FEEDBACK, response);
            track(trackingConsts.MICROSERVICES.TESTED_CONNECTION, response);
        } catch (e) {
            setSelect(TYPES.SELECT.CONNECTION_FEEDBACK, {
                message: e?.response?.data?.message || 'Some error occurred!'
            });
            track(trackingConsts.MICROSERVICES.TESTED_CONNECTION, {error: e?.response?.data?.message})
        } finally {
            clearWaiting(TYPES.WAITING.PROCESSING.CREATE_TEST_CONNECTION);
        }
    };

    return createTestConnectionFlow;
};

export default useCreateTestConnectionFlow;