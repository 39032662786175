import { useToast } from '@chakra-ui/react';
import TYPES from '../../config/types';
import { models, queryClient } from '../queries';
import useStore from '../zustand';
import { apiWithToken } from '../utils/dispatchRecipe';
import { apiKeys } from '../utils/apiKeys'
import useTracking from '../hooks/useTracking';
import trackingConsts from '../../config/tracking';
import useListEnvVars from '../hooks/useListEnvVars';
import useCreateEnvVarInteraction from '../interactions/useCreateEnvVarInteraction';

const environmentVariables = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production'
};

const MAX_ENV_VARS_PER_PROJECT = 100;

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Payload {
  [k: string]: any;
}

interface IProps {
  triggerIdCreate: string;
  triggerIdGetStatus: string;
  successfulCreationInitiation: Record<string, unknown>;
}

 // remove any excess whitespace, replace internal whitespace with '_', and force UPPER:
const getNameFromTitle = (title: string) =>
  title.trim().replace(/\s/g, '_').toUpperCase();

const getEnvVarName = (name: string, envs: string[], initName: string, number = 0) => {
  if (!envs.includes(name)) {
    return name;
  }
  const nextIndex = number + 1;
  if (nextIndex > MAX_ENV_VARS_PER_PROJECT) {
    throw new Error('Environment variable creation limit exceeded')
  }
  const newName = `${initName}_${nextIndex}`;
  return getEnvVarName(newName, envs, initName, nextIndex);
};

const queryParams = {
  retry: false,
  refetchOnWindowFocus: false,
  staleTime: 0
}

const useCreateConnectionFlow = (props: IProps) => {

  const { createEnvVar } = useCreateEnvVarInteraction();

  // Get list of all existing env var names
  const { data } = useListEnvVars(queryParams);
  const envVarsList = data?.envsArray?.map(({name}) => name) || [];

  const getEnvVarParams = (name: string, slug: string, envVars: string[]) => {
    let development = ''; 
    let production = '';
    if (envVars.includes(environmentVariables.DEVELOPMENT)) {
      development = slug
    }
    if (envVars.includes(environmentVariables.PRODUCTION)) {
      production = slug
    }
    return {
      name,
      development,
      production
    }
  }

  const { successfulCreationInitiation } = props;

  const { setWaiting, clearWaiting, setSelect, closeModal } = useStore(
    (store) => ({
      setWaiting: store.setWaiting,
      clearWaiting: store.clearWaiting,
      setSelect: store.setSelect,
      closeModal: store['modal/close'],
    })
  );

  const { track } = useTracking();

  const toast = useToast();
  const createConnection = (payload) =>
    apiWithToken({
      url: apiKeys['create.microservice'],
      method: 'POST',
      payload,
    });
  const createConnectionFlow = async (payload: Payload) => {
    try {
      setWaiting(TYPES.WAITING.PROCESSING.CREATE_CONNECTION, true);
      setSelect(
        TYPES.SELECT.CONNECTION_CREATE_PROCESSING_MESSAGE,
        TYPES.MICROSERVICE_INSTANCE_CREATION_STATUS.CONNECTING
      );
      const connection = await createConnection({...payload, formData: {...payload?.formData, env: undefined}});
     
      // Get final Env Var name
      const envVarTitle = `${connection?.frontend?.spec?.title}_connection_key`;
      const name = getNameFromTitle(envVarTitle);
      const finalEnvVarName = getEnvVarName(name, envVarsList, name);

      // Value of the Environment Variable
      const slug = connection?.slug;

      // Get Create Env Var payload
      const params = getEnvVarParams(finalEnvVarName, slug, payload?.formData?.env);

      // Create an Env Var
      await createEnvVar(params);

      queryClient.refetchQueries({
        queryKey: [models.microserviceClusters.name],
      });
      closeModal();
      toast({
        title: successfulCreationInitiation?.title || 'Connection initiated.',
        description:
          successfulCreationInitiation?.description ||
          'We have initiated your connection.It should take under 30 seconds to spin up.',
        status: 'success',
        duration: 10000,
        isClosable: true,
        position: 'top',
      });
      track(trackingConsts.MICROSERVICES.CONNECTED_MICROSERVICE, {
        success: true,
        name: connection?.name,
        type: connection?.type,
      });
    } catch (error) {
      const message = error?.response?.data?.message || error?.message || 'Some error occurred!'
      setSelect(TYPES.SELECT.CONNECTION_FEEDBACK, {
        message
      });
      track(trackingConsts.MICROSERVICES.CONNECTED_MICROSERVICE, {
        error: message
      });
    } finally {
      clearWaiting(TYPES.WAITING.PROCESSING.CREATE_CONNECTION);
    }
  };

  return createConnectionFlow;
};

export default useCreateConnectionFlow;
