import getEnvFromHost from './getEnvFromHost';

/* eslint-disable import/prefer-default-export */
const domains = {
  localhost: 'https://development-api.buildable.io',
  development: 'https://development-api.buildable.io',
  staging: 'https://staging-api.buildable.io',
  beta: 'https://beta-api.buildable.dev',
  production: 'https://api.buildable.dev',
  default: 'https://development-api.buildable.dev',
};

export function getUrl() {
  const env = getEnvFromHost();
  const domain = domains[env] ? domains[env] : domains.production;
  return `${domain}/internal/v1`;
}

export const path = getUrl();

export const apiKeys = {
  'get.logicFlow': `${path}/flows/get`,
  'get.autoComplete': `${path}/flows/get-types`,
  'get.emptyNode': `${path}/flows/get.empty.node`,
  'get.snap': `${path}/flows/get.snap`,
  'get.templateListDefault': `${path}/node-templates/list-default`,
  'get.templateList': `${path}/node-templates/list`,
  'get.templateFilters': `${path}/node-templates/get-filters`,

  'create.logicFlow': `${path}/flows/create.empty.flow`,
  'create.node': `${path}/flows/add.node`,
  'create.hook': `${path}/flows/add.empty.hook`,
  'create.template.node': `${path}/flows/add.template.node`,

  'deploy.logicFlow': `${path}/flows/deploy`,

  'delete.node': `${path}/flows/delete.node`,
  'delete.flow': `${path}/flows/delete`,
  'delete.hook': `${path}/flows/delete.hook`,

  'fork.logicFlow': `${path}/flows/fork`,

  'list.logicFlows': `${path}/flows/list`,
  'list.activities': `${path}/flows/list.activities`,

  'revert.activity': `${path}/flows/revert`,

  'search.flows': `${path}/flows/search`,
  'search.nodeTemplates': `${path}/node-templates/search`,

  'update.node': `${path}/flows/update.single.node`,
  'update.node.exec': `${path}/flows/update.single.node.s.exec`,
  'update.node.input': `${path}/flows/update.single.node.s.input`,
  'update.node.sample': `${path}/flows/update.single.node.s.sample`,
  'update.logicFlow': `${path}/flows/update`,
  'update.response': `${path}/flows/update.response`,
  'update.hook': `${path}/flows/update.hook`,

  // µservices
  'create.microservice': `${path}/microservices/create`,
  'get.microservices': `${path}/microservices/list-canvas-nodes`,
  'sync.microservice': `${path}/microservices/sync-and-generate-types`,
  'delete.microservice': `${path}/microservices/delete`,
  'test-connection.microservice': `${path}/microservices/test-connection`,
  'list.frontend.microservice': `${path}/microservice/list-frontend`,
  'list.microservice.definitions': `${path}/microservices/list-microservice-definitions`,
  'list.connected.microservices': `${path}/microservices/list-connected-microservices`,
  'upload.media':
    'https://upload.buildable.dev/upload/live-2a80d157-ecb0-4279-ac1d-901b93513b9b',
  'create.environment.variables': `${path}/environment-variables/create`,
  'update.environment.variables': `${path}/environment-variables/update`,
  'list.environment.variables': `${path}/environment-variables/get-environment`,
  'delete.environment.variables': `${path}/environment-variables/delete`,
  'get.homePage.data':
    'https://api.buildable.dev/flow/v1/call/live/dashboard-home-page-d682143234',
  'get.logs': `${path}/flows/list.events`
};
