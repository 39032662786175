import {
    Box,
    Center,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    useBreakpointValue,
    useColorModeValue,
    Text,
    Heading,
    Button,
  } from '@chakra-ui/react';
import useDeleteRelationFlow from '../../../logic/flows/useDeleteRelationFlow';
  import TYPES from '../../../config/types';
  import useStore from '../../../logic/zustand';

  const DeleteRelation = () => {
    const { isOpenModal, closeModal, append, waiting, triggerId, relationName } = useStore((store) => ({
      isOpenModal: store.modal.isOpen,
      closeModal: store['modal/close'],
      append: store.select[TYPES.SELECT.APPEND],
      relationName: store.select[TYPES.SELECT.RELATION_NAME],
      triggerId: store.select[TYPES.SELECT.RELATION_DELETE_TRIGGER_ID],
      waiting: store.waiting[TYPES.WAITING.PROCESSING.DELETE_RELATION],
    }));
    const removeRelation = useDeleteRelationFlow({
      endpoint: triggerId
    });
    const size = useBreakpointValue({ md: 'lg' });
    const isCentered = useBreakpointValue({ md: true });
    const bg = useColorModeValue('white', 'black');
    return (
      <Modal
        size={size}
        onClose={closeModal}
        isOpen={isOpenModal}
        isCentered={isCentered}
      >
        <ModalOverlay />
        <ModalContent rounded={[0, 'md']}>
          <ModalCloseButton />
          <ModalBody maxH="max-content" rounded="md" bg={bg} py="5">
            <Box p={5} alignItems="center">
              <Box w="100%">
                <Center>
                  <Heading textAlign="center" size="sm">Are you sure you want to delete {relationName}?</Heading>
                </Center>
              </Box>
              <Text mt={3} size="md" textAlign="center">
                Deleting a service cannot be reversed.
              </Text>
              <Button
                isDisabled={waiting}
                onClick={() => removeRelation({ name: relationName, ...append, })}
                mt={5}
                minW="100%"
                colorScheme="red"
              >
                Delete
              </Button>
              <Button
                onClick={() => closeModal()}
                mt={5}
                minW="100%"
                // variant={'unstyled'}
                colorScheme="gray"
              >
                Cancel
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  export default DeleteRelation;
