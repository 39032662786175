import { Menu, MenuList } from '@chakra-ui/react';

interface ButtonMenuProps {
  buttonSlot?: React.ReactNode;
  children: React.ReactNode;
  width?: string;
}

export default function ButtonMenu({
  buttonSlot,
  children,
  width
}: ButtonMenuProps) {
  return (
    <Menu>
      {buttonSlot}
      <MenuList w={width} shadow="lg" py="4" px="3" bg="gray.800">
        {children}
      </MenuList>
    </Menu>
  );
}

ButtonMenu.defaultProps = {
  buttonSlot: <></>,
  width: 'max-content'
};