import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay
} from '@chakra-ui/react';

interface IDrawer {
  isOpen: boolean;
  onClose: () => void;
  placement?: 'right' | 'left' | 'top' | 'bottom';
  size?: string;
  children?: React.ReactNode;
  headerSlot?: React.ReactNode;
  bgColor?: string;
}

export default function DrawerComponent({
  isOpen,
  onClose,
  placement,
  size,
  children,
  bgColor,
  headerSlot
}: IDrawer) {
  return (
    <Drawer size={size} isOpen={isOpen} placement={placement} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent bg={bgColor}>
        <DrawerCloseButton />
        <DrawerHeader>{headerSlot}</DrawerHeader>
        {children}
      </DrawerContent>
    </Drawer>
  );
}

DrawerComponent.defaultProps = {
  placement: 'right',
  size: 'xl',
  children: <></>,
  headerSlot: <></>,
  bgColor: 'gray.900'
};
