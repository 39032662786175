const reactQueryKeys = {
  flowList: 'flowList',
  flowSearch: 'flowSearch',
  relationList: 'relationList',
  microserviceFrontend: 'microserviceFrontend',
  relationData: 'relationData',
  envVarsList: 'envVarsList',
  listHomePageData: 'listHomePageData',
  logs: 'logs',
  user: 'user'
};
export default reactQueryKeys
