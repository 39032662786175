import _classCallCheck from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/classCallCheck";
import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";
export var ActionModel = function ActionModel(key, icon, action, alt) {
  _classCallCheck(this, ActionModel);

  _defineProperty(this, "key", void 0);

  _defineProperty(this, "icon", void 0);

  _defineProperty(this, "action", void 0);

  _defineProperty(this, "alt", void 0);

  this.key = key;
  this.icon = icon;
  this.action = action;
  this.alt = alt;
};
export var ActionTypes;

(function (ActionTypes) {
  ActionTypes["DELETE"] = "delete";
  ActionTypes["EDIT"] = "edit";
  ActionTypes["PAUSE"] = "pause";
  ActionTypes["EVENT"] = "event";
  ActionTypes["SAMPLE"] = "sample";
  ActionTypes["INPUT"] = "input";
  ActionTypes["VERSION_CONTROL"] = "version-control";
  ActionTypes["VIEW"] = "view";
  ActionTypes["CREATE_VERSION"] = "create-version";
  ActionTypes["SECURITY"] = "security";
  ActionTypes["RESPONSE"] = "response";
  ActionTypes["BEFORE_INPUT"] = "before-input";
  ActionTypes["AFTER_INPUT"] = "after-input";
  ActionTypes["ONERROR_INPUT"] = "onError-input";
  ActionTypes["BEFORE_EDIT"] = "before-edit";
  ActionTypes["AFTER_EDIT"] = "after-edit";
  ActionTypes["ONERROR_EDIT"] = "onError-edit";
  ActionTypes["BEFORE_RESPONSE"] = "before-response";
  ActionTypes["AFTER_RESPONSE"] = "after-response";
  ActionTypes["ONERROR_RESPONSE"] = "onError-response";
})(ActionTypes || (ActionTypes = {}));