export var reactQueryKeys = {
  logicFlows: 'logicFlows',
  logicFlow: 'logicFlow',
  logicFlowActivities: 'logicFlowActivities',
  activitySnap: 'activitySnap',
  nodeExec: 'nodeExec',
  nodeUpdate: 'nodeUpdate',
  autoCompleteTypes: 'autoCompleteTypes',
  microservices: 'microservices',
  templates: 'templates'
};