import {
  Box, Button,
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text, useBreakpointValue, useColorModeValue
} from '@chakra-ui/react';
import useStore from '../../../logic/zustand';
import TYPES from '../../../config/types';
import useDeleteEnvVarInteraction from '../../../logic/interactions/useDeleteEnvVarInteraction';

const DeleteEnvVar = () => {

  const { deleteEnvVar, isLoading } = useDeleteEnvVarInteraction();

  const size = useBreakpointValue({ md: 'lg' });
  const isCentered = useBreakpointValue({ md: true });
  const bg = useColorModeValue('white', 'black');
  const { isOpenModal, closeModal, name } = useStore((store) => ({
    isOpenModal: store.modal.isOpen,
    closeModal: store['modal/close'],
    name: store.select[TYPES.SELECT.ENV_VAR_NAME]
  }));
  const title = `Are you sure you want to delete ${name}?`;
  const subTitle = 'Deleting an environment variable cannot be reversed.';
  return (
    <Modal
      size={size}
      onClose={closeModal}
      isOpen={isOpenModal}
      isCentered={isCentered}
    >
      <ModalOverlay />
      <ModalContent rounded={[0, 'md']}>
        <ModalCloseButton />
        <ModalBody maxH="max-content" rounded="md" bg={bg} py="5">
          <Box p={5} alignItems="center">
            <Heading w="100%" textAlign="center" size="sm">{title}</Heading>
            <Text mt={3} size="md" textAlign="center">
              {subTitle}
            </Text>
            <Button
              isLoading={isLoading}
              onClick={() => deleteEnvVar({ name })}
              mt={5}
              minW="100%"
              colorScheme="red"
            >
              Delete
            </Button>
            <Button
              onClick={() => closeModal()}
              mt={5}
              minW="100%"
              colorScheme="gray"
            >
              Cancel
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default DeleteEnvVar
