import { useToast } from '@chakra-ui/react';
import trackingConsts from '../../config/tracking';
import { useForkFlow } from '../hooks/useForkFlow';
import useTracking from '../hooks/useTracking';

function useForkFlowInteraction() {
  const { forkFlow } = useForkFlow();
  const { track } = useTracking();
  const toast = useToast();
  const fork = async (id) => {
    try {
      await forkFlow({ id });
      toast({
        title: 'Flow forked successfully.',
        status: 'success',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
      track(trackingConsts.FLOWS.FORKED_FLOW, {success: true});
    } catch (e) {
      const description =
        e?.response?.data?.message || 'Could not fork flow';
      toast({
        title: 'Some error occurred',
        description,
        status: 'error',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
      track(trackingConsts.FLOWS.FORKED_FLOW, {error: description});
    }
  };

  return { fork };
}

export default useForkFlowInteraction;
