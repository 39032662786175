/**
 *
 * **Represents**: A value of one of two possible types (a disjoint union).
 *
 * **Types**: An instance of `Either` is either an instance of `Left` or `Right`.
 *
 * **Use case**: A common use of `Either` is as an alternative to `Option` for dealing with possible missing values. In this usage,
 * `None` is replaced with a `Left` which can contain useful information. `Right` takes the place of `Some`. Convention
 * dictates that `Left` is used for failure and `Right` is used for success.
 *
 */

/**
 * Returns `true` if the either is an instance of `Left`, `false` otherwise.
 */
export var isLeft = function isLeft(ma) {
  return ma._tag === 'Left';
};
/**
 * Returns `true` if the either is an instance of `Right`, `false` otherwise.
 */

export var isRight = function isRight(ma) {
  return ma._tag === 'Right';
};
/**
 * Constructs a new `Either` holding a `Left` value. This usually represents a failure.
 */

export var left = function left(l) {
  return {
    _tag: 'Left',
    value: l
  };
};
/**
 * Constructs a new `Either` holding a `Right` value. This usually represents a successful value.
 */

export var right = function right(r) {
  return {
    _tag: 'Right',
    value: r
  };
}; // eslint-disable-next-line @typescript-eslint/no-namespace

export var Either;

(function (_Either) {
  var map = _Either.map = function (f) {
    return function (fa) {
      return isLeft(fa) ? fa : right(f(fa.value));
    };
  };

  var match = _Either.match = function (input, _ref) {
    var onLeft = _ref.onLeft,
        onRight = _ref.onRight;

    switch (input._tag) {
      case 'Left':
        return onLeft(input.value);

      case 'Right':
        return onRight(input.value);

      default:
        throw new Error('Unknown Either type');
    }
  };
})(Either || (Either = {}));