import {
    Box,
    Center,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    useBreakpointValue,
    useColorModeValue,
    Text,
    Heading,
    Button,
  } from '@chakra-ui/react';
  import Mustache from 'mustache';
import useRemoveMicroServiceInstanceFlow from '../../../logic/flows/useRemoveMicroServiceInstance';
  import TYPES from '../../../config/types';
  import useStore from '../../../logic/zustand';


  const DeleteMicroservice = () => {


    const { isOpenModal, closeModal, record, waiting } = useStore((store) => ({
      isOpenModal: store.modal.isOpen,
      closeModal: store['modal/close'],
      record: store.select[TYPES.SELECT.MICROSERVICE_RECORD],
      waiting: store.waiting[TYPES.WAITING.PROCESSING.DELETE_MICROSERVICE],
    }));

    const removeMicroservice = useRemoveMicroServiceInstanceFlow({
      endpoint: record?.frontend?.features?.actions?.delete?.trigger?.endpoint
    });

    const size = useBreakpointValue({ md: 'lg' });
    const isCentered = useBreakpointValue({ md: true });
    const bg = useColorModeValue('white', 'black');

    const title = record ? Mustache.render(record?.frontend?.features?.actions?.delete?.spec?.title, record) :
      "Are you sure you want to delete?"
    const subTitle = record?.frontend?.features?.actions?.delete?.spec?.subTitle;
    const isDeleteDisabled = !record?.frontend?.features?.actions?.delete?.enabled;
    const append = record?.frontend?.features?.actions?.delete?.trigger?.append;

    return (
      <Modal
        size={size}
        onClose={closeModal}
        isOpen={isOpenModal}
        isCentered={isCentered}
      >
        <ModalOverlay />
        <ModalContent rounded={[0, 'md']}>
          <ModalCloseButton />
          <ModalBody maxH="max-content" rounded="md" bg={bg} py="5">
            <Box p={5} alignItems="center">
              <Box w="100%">
                <Center>
                  <Heading textAlign="center" size="sm">{title}</Heading>
                </Center>
              </Box>
              <Text mt={3} size="md" textAlign="center">
                {subTitle}
              </Text>
              <Button
                isDisabled={isDeleteDisabled || waiting}
                onClick={() => removeMicroservice({ id: record?._id, ...append })}
                mt={5}
                minW="100%"
                colorScheme="red"
              >
                Delete
              </Button>
              <Button
                onClick={() => closeModal()}
                mt={5}
                minW="100%"
                // variant={'unstyled'}
                colorScheme="gray"
              >
                Cancel
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  export default DeleteMicroservice;
