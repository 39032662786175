import { useToast } from "@chakra-ui/react";
import TYPES from "../../config/types";
import { models, queryClient } from "../queries";
import useStore from "../zustand";
import { apiWithToken } from '../utils/dispatchRecipe';
import { apiKeys, path } from '../utils/apiKeys';
import useTracking from "../hooks/useTracking";
import trackingConsts from "../../config/tracking";

interface Payload {
    id: string
}

const useRemoveMicroServiceInstanceFlow = ({endpoint}: {endpoint: string}) => {
    const {setSelect, closeModal, setWaiting, clearWaiting} = useStore((store) => ({
        setSelect: store.setSelect,
        closeModal: store['modal/close'],
        setWaiting: store.setWaiting,
        clearWaiting: store.clearWaiting
    }))


  const toast = useToast();
  const { track } = useTracking();
  const removeMicroService = (payload) => apiWithToken({
    url: `${path}${endpoint}`,
    method: 'POST',
    payload
  })
    const removeMicroServiceInstance = async (payload: Payload) => {
        try {
            setWaiting(TYPES.WAITING.PROCESSING.DELETE_MICROSERVICE, true);
            const resp = await removeMicroService(payload);
            queryClient.refetchQueries({
                queryKey: [models.microserviceClusters.name]
            })
            toast({
                title: 'Instance deleted.',
                description: 'Your microservice instance has been deleted.',
                status: 'success',
                duration: 10000,
                isClosable: true,
                position: 'top'
            })
            track(trackingConsts.MICROSERVICES.CONFIRMED_DELETE_MICROSERVICE, resp)
        } catch (error) {
            const description = error?.response?.data?.message || 'Please try again later';
            toast({
                title: 'Some error occurred.',
                description,
                status: 'error',
                duration: 10000,
                isClosable: true,
                position: 'top'
            });
            track(trackingConsts.MICROSERVICES.CONFIRMED_DELETE_MICROSERVICE, {error: description})
        } finally {
            closeModal({type: TYPES.MODALS.DELETE_MICROSERVICE});
            clearWaiting(TYPES.WAITING.PROCESSING.DELETE_MICROSERVICE);
            setSelect(TYPES.SELECT.MICROSERVICE_RECORD, false);
        }
    };

    return removeMicroServiceInstance;
};

export default useRemoveMicroServiceInstanceFlow;
