import { Skeleton } from '@chakra-ui/react';

interface IListItemSkeleton {
  height?: string;
}

export default function ListItemSkeleton({ height }: IListItemSkeleton) {
  return <Skeleton borderRadius="0px" height={height} />;
}

ListItemSkeleton.defaultProps = {
  height: '25px'
};
