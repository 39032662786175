import {
  FormControl,
  Checkbox,
  FormLabel,
  FormErrorMessage,
  CheckboxGroup
} from "@chakra-ui/react";
import { ReactElement } from "react";
import {
  Control,
  Controller,
  FieldErrors,
} from "react-hook-form";
import { IOptions, IRules } from "../shared/interface";

interface ICheckboxProps {
  control: Control;
  name: string;
  label: string;
  errors: FieldErrors;
  rules: IRules | undefined;
  options: IOptions[] | undefined
}

export function FormCheckbox(props: ICheckboxProps): ReactElement {
  const { control, name, label, errors, rules, options } = props;
  const defaultValue = options && options.filter(cb => cb.isChecked).map(cb => cb.value)

  return (
    <FormControl isInvalid={errors[name]} name={name}>
      <FormLabel fontWeight='bold' htmlFor={label}>
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { ref, onChange, onBlur, value } }) => (
          <CheckboxGroup value={value} onChange={onChange}>
            {options?.map((cb) => (
              <Checkbox
                onBlur={onBlur}
                name={cb.name}
                ref={ref}
                value={cb.value}
                mr='10'
              >
                {cb.name}
              </Checkbox>
            ))}
          </CheckboxGroup>
        )}
        rules={rules}
      />
      <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
    </FormControl>
  );
}
