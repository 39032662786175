import { Flex } from '@chakra-ui/layout';
import { useMenuButton } from '@chakra-ui/react';

interface MenuButton {
  imageSlot?: React.ReactNode;
  children: React.ReactNode;
  onClick: () => void;
}

export default function MenuButton({
  imageSlot,
  children,
  onClick
}: MenuButton) {
  const buttonProps = useMenuButton();
  const handleClick = (e) => {
    buttonProps?.onClick(e);
    onClick();
  };
  return (
    <Flex
      // TODO: Figure out the exact props that need to be passed
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonProps}
      onClick={handleClick}
      justify="space-between"
      as="button"
      w="full"
      display="flex"
      alignItems="center"
      rounded="lg"
      py="2"
      fontSize="sm"
      cursor="pointer"
      outline="0"
      transition="all 0.2s"
      _active={{ bg: 'gray.800' }}
      _focus={{ shadow: 'outline' }}
    >
      {imageSlot}
      {children}
    </Flex>
  );
}

MenuButton.defaultProps = {
  imageSlot: <></>
};
