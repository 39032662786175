import { useToast } from '@chakra-ui/react';
import trackingConsts from '../../config/tracking';
import { useCreateEnvVar } from '../hooks/useCreateEnvVar';
import useTracking from '../hooks/useTracking';

function useCreateEnvVarInteraction() {
  const toast = useToast();
  const { track } = useTracking();
  const { createEnvVar: create, isLoading } = useCreateEnvVar();
  const createEnvVar = async (payload) => {
    try {
      const resp = await create(payload);
      toast({
        title: 'Environment variable created successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    } catch (error) {
      const description =
        error?.response?.data?.message ||
        'Environment variable could not be created';
      toast({
        title: 'Some error occurred',
        description,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  return { createEnvVar, isLoadingCreateEnvVar: isLoading };
}

export default useCreateEnvVarInteraction;
