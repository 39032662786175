import { useState } from 'react';
import { useColorModeValue, VStack, Text, IconButton } from '@chakra-ui/react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { HStack } from '@chakra-ui/layout';

const SingleKeyLabel = ({ label = 'DEVELOPMENT VALUE', value = 'f1c8a7d4-70cc-4295-b044-b72e3d3c42fd', }) => {
  const [redacted, setRedacted] = useState(true);
  const color = useColorModeValue('nav.900', 'navy.100');
  const redactedValue = '•••••••••••••••';

  return (
    <VStack spacing="1" align="start" maxW="30%">
      <Text color={color} fontWeight="500" fontSize="sm">{label}</Text>
      <HStack spacing="2" align="center">
        <IconButton
          aria-label="show"
          icon={redacted ? <AiFillEye /> : <AiFillEyeInvisible />}
          size="xs"
          variant="ghost"
          onClick={() => setRedacted(!redacted)}
        />
        <Text
          wordBreak='break-word'
          bg={redacted ? '' : 'navy.700'}
          color={color}
          p={redacted ? '' : '2px'}
        >
          {redacted ? redactedValue : value}
        </Text>
      </HStack>
    </VStack>
  );
};

export default SingleKeyLabel
