/**
 *
 * **Represents**: Optional values.
 *
 * **Types**: An instance of `Option` is either an instance of `None` or `Some`.
 *
 * **Use case**: The most idiomatic way to use an `Option<T>` instance is to treat it as a collection where `None` represents
 * a possible missing value. `Some` takes the place of `Some<T>`.
 *
 */

/**
 * Returns `true` if the option is an instance of `None`, `false` otherwise.
 */
export var isNone = function isNone(ma) {
  return ma._tag === 'None';
};
/**
 * Returns `true` if the option is an instance of `Some`, `false` otherwise.
 */

export var isSome = function isSome(ma) {
  return ma._tag === 'Some';
};
/**
 * `None` doesn't have a constructor, use it as a value. Represents a missing value.
 */

export var none = {
  _tag: 'None'
};
/**
 * Constructs a `Some`. Represents an optional value that exists.
 */

export var some = function some(t) {
  return {
    _tag: 'Some',
    value: t
  };
}; // eslint-disable-next-line @typescript-eslint/no-namespace

export var Option;

(function (_Option) {
  var map = _Option.map = function (f) {
    return function (fa) {
      return isNone(fa) ? none : some(f(fa.value));
    };
  };

  var match = _Option.match = function (input, _ref) {
    var onNone = _ref.onNone,
        onSome = _ref.onSome;

    switch (input._tag) {
      case 'None':
        return onNone();

      case 'Some':
        return onSome(input.value);

      default:
        throw new Error('Unknown Option type');
    }
  };
})(Option || (Option = {}));