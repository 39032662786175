import { useQuery } from 'react-query';
import dispatchRecipe from '../utils/dispatchRecipe';

interface IUseGet {
  queryKey: string;
  triggerId: string;
  id?: string;
}

const useGet = (props: IUseGet) => {
  const { queryKey, triggerId, id, ...restOfParams } = props;
  if (!triggerId) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery (
    [queryKey, id],
    () =>
      dispatchRecipe({
        triggerId,
        payload: { id },
      }),
    { retry: false, refetchOnWindowFocus: true, staleTime: 0, ...restOfParams }
  );
};

export default useGet;
