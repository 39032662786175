import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { apiKeys } from '@buildablecore/src/logic/utils/apiKeys';
import { apiWithToken } from '@buildablecore/src/logic/utils/dispatchRecipe';
import { useMutation, useQueryClient } from 'react-query';
import { reactQueryKeys } from '../queryKeys';
export default function useUpdateResponse() {
  var queryClient = useQueryClient();
  var mutation = useMutation(function (payload) {
    return apiWithToken({
      url: apiKeys['update.response'],
      method: 'POST',
      payload: payload
    });
  }, {
    onMutate: function onMutate(data) {
      var previousFlow = queryClient.getQueryData([reactQueryKeys.logicFlow, data === null || data === void 0 ? void 0 : data.id]); // Optimistically update to the new value

      queryClient.setQueryData([reactQueryKeys.logicFlow, data === null || data === void 0 ? void 0 : data.id], _objectSpread(_objectSpread({}, previousFlow), {}, {
        response: data.response
      }));
    },
    onSuccess: function onSuccess(data, payload) {
      queryClient.invalidateQueries([reactQueryKeys.logicFlow, payload === null || payload === void 0 ? void 0 : payload.id]);
    },
    onError: function onError(err, data) {
      queryClient.invalidateQueries([reactQueryKeys.logicFlow, data === null || data === void 0 ? void 0 : data.id]);
    }
  });
  return {
    updateResponse: mutation.mutate
  };
}