import { useToast } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import TYPES from '../../config/types';
import { models, queryClient, useDelete } from '../queries';
import useStore from '../zustand';
import { apiWithToken } from '../utils/dispatchRecipe';
import { path } from '../utils/apiKeys';
import queryKeys from '../queryKeys';
import useTracking from '../hooks/useTracking';
import trackingConsts from '../../config/tracking';

interface IProps {
  endpoint: string;
}

const useDeleteRelationFlow = (props: IProps) => {
  const router = useRouter();
  const slug = router?.query?.id;
  const toast = useToast();
  const { track } = useTracking();
  const { setSelect, closeModal, setWaiting, clearWaiting } = useStore(
    (store) => ({
      setSelect: store.setSelect,
      closeModal: store['modal/close'],
      setWaiting: store.setWaiting,
      clearWaiting: store.clearWaiting,
    })
  );
  const { endpoint } = props;

  const deleteRelation = ((payload) => apiWithToken({
    url: `${path}${endpoint}`,
    method: "POST",
    payload
  }))

  const deleteRelationFlow = async (payload) => {
    try {
      setWaiting(TYPES.WAITING.PROCESSING.DELETE_RELATION, true);
      const resp = await deleteRelation(payload);
      queryClient.refetchQueries({
        queryKey: [queryKeys.microserviceFrontend, slug],
      });
      toast({
        title: 'Relation deleted.',
        description: 'Your relation has been deleted.',
        status: 'success',
        duration: 10000,
        isClosable: true,
        position: 'top',
      });
      track(trackingConsts.MICROSERVICES.CONFIRMED_DELETE_COLLECTION, resp);
    } catch (e) {
      const description = e?.response?.data?.message || 'Could not delete relation';
      toast({
        title: 'Some error occurred.',
        description,
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'top',
      });
      track(trackingConsts.MICROSERVICES.CONFIRMED_DELETE_COLLECTION, {error: description})
    } finally {
      closeModal({ type: TYPES.MODALS.DELETE_RELATION });
      clearWaiting(TYPES.WAITING.PROCESSING.DELETE_RELATION);
      setSelect(TYPES.SELECT.APPEND, false);
      setSelect(TYPES.SELECT.RELATION_DELETE_TRIGGER_ID, false);
      setSelect(TYPES.SELECT.RELATION_NAME, false);
    }
  };

  return deleteRelationFlow;
};

export default useDeleteRelationFlow;
