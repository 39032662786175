import { Button } from '@chakra-ui/react';

interface IHighlightButtonProps {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent) => void;
  colorScheme: string;
  icon?: JSX.Element;
  disabled?: boolean;
}

export default function HighlightButton({
  children,
  onClick,
  colorScheme,
  icon,
  disabled,
}: IHighlightButtonProps) {
  return (
    <Button
      w="100%"
      h="25px"
      size="md"
      onClick={onClick}
      variant="ghost"
      colorScheme={colorScheme}
      leftIcon={icon}
      isDisabled={disabled}
    >
      {children}
    </Button>
  );
}

HighlightButton.defaultProps = {
  icon: <></>,
  disabled: false,
};
