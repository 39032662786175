import { useQuery } from 'react-query';
import queryKeys from '../queryKeys';
import { apiWithToken } from '../utils/dispatchRecipe';
import { apiKeys } from '../utils/apiKeys';

const useSearchFlows = ({ term }: { term: string }) => {
  const args = useQuery({
    queryKey: [queryKeys.flowSearch, term],
    queryFn: () =>
      term &&
      apiWithToken({
        url: apiKeys['search.flows'],
        method: 'POST',
        payload: {
          term,
          page: 1,
          pageSize: 100,
          fields: ['name', 'slug', 'frontend.spec', 'type'],
        },
      }),
    staleTime: 0,
    refetchOnWindowFocus: true,
    retry: false,
  });

  return { ...args };
};

export default useSearchFlows;
