/* eslint-disable @typescript-eslint/no-explicit-any */
import { RepeatIcon } from '@chakra-ui/icons';
import { Flex, IconButton, Tooltip } from '@chakra-ui/react';
import useSyncMicroServiceClusterFlow from '../../../logic/flows/useSyncMicroServiceCluster';

interface IProps {
  record: any;
}

const Sync = (props: IProps) => {
  const { record } = props;

  const isDisabled = !record?.frontend?.features?.actions?.sync?.enabled;
  const label = record?.frontend?.features?.actions?.sync?.spec?.tooltip || "Sync data";
  const append = record?.frontend?.features?.actions?.sync?.trigger?.append;

  const syncMicroService = useSyncMicroServiceClusterFlow({
    endpoint: record?.frontend?.features?.actions?.sync?.trigger?.endpoint
  });
  return (
    isDisabled
    ? <Tooltip  placement="bottom-start" fontSize="sm" label={label}>
        <IconButton
          isDisabled
          size="sm"
          aria-label="Re-sync"
          icon={<RepeatIcon  h={3} w={3} />}
          ml={4}
        />
      </Tooltip>
    : <Flex onClick={() => syncMicroService({microserviceSlug: record?.slug, ...append})}>
      <Tooltip placement="bottom-start" fontSize="sm" label={label}>
        <IconButton
          size="sm"
          aria-label="Re-sync"
          icon={<RepeatIcon h={3} w={3} />}
          ml={4}
        />
      </Tooltip>
    </Flex>
  );
};

export default Sync;
