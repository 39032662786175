import { useState } from 'react';
import useListRelationData from '../hooks/useListRelationData';

interface IProps {
  fields?: string[],
  query?: {[K: string]: any}
}


function useListRelationDataInteraction(props: IProps) {

  const { fields, query } = props;
  const [page, setPage] = useState<number>(1);
  const [sort, setSort] = useState("-_id");

  const { data, status } = useListRelationData({
    page,
    sort,
    fields,
    query
  });

  const goToPage = (newPage) => {
    if (newPage > 0 && newPage <= data?.totalPages) {
      setPage(newPage);
    }
  };

  const hasNextPage = data?.page < data?.totalPages;
  const hasPreviousPage = data?.page !== 1;

  const getPage = (num = 1) => {
    if (num === 1 && !hasNextPage) {
      return;
    }
    if (num === -1 && !hasPreviousPage) {
      return;
    }
    setPage(page + num);
  };

  const handleSort = (field) => setSort(field);

  return {
    data,
    status,
    goToPage,
    getPage,
    handleSort,
    back: () => getPage(-1),
    next: () => getPage(1),
    sort,
    page: data?.page,
    pageSize: data?.pageSize,
    total: data?.total,
    totalPages: data?.totalPages
  };
}

export default useListRelationDataInteraction;
