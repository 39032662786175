import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { apiKeys } from '@buildablecore/src/logic/utils/apiKeys';
import { apiWithToken } from '@buildablecore/src/logic/utils/dispatchRecipe';
import { reactQueryKeys } from '@Builder/logic/queryKeys';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';

function _baseGetLogicFlow(_ref) {
  var url = _ref.url,
      id = _ref.id,
      _ref$staleTime = _ref.staleTime,
      staleTime = _ref$staleTime === void 0 ? Infinity : _ref$staleTime,
      _ref$options = _ref.options,
      options = _ref$options === void 0 ? {} : _ref$options,
      queryKey = _ref.queryKey;
  return useQuery(_objectSpread({
    queryKey: queryKey,
    queryFn: function queryFn() {
      return apiWithToken({
        url: url,
        method: 'POST',
        payload: {
          id: id
        }
      });
    },
    staleTime: staleTime
  }, options));
}

export default function useGetFlow(id) {
  var params = id ? getFlowFromSnap(id) : getCurrentLogicFlow();
  return _baseGetLogicFlow(params);
}
export function getCurrentLogicFlow() {
  var _router$query;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  var router = useRouter();
  var id = router === null || router === void 0 ? void 0 : (_router$query = router.query) === null || _router$query === void 0 ? void 0 : _router$query.id;
  var params = {
    id: id,
    queryKey: [reactQueryKeys.logicFlow, id],
    url: apiKeys['get.logicFlow'],
    staleTime: 1000,
    options: {
      enabled: !!id
    }
  };
  return params;
}
export function getFlowFromSnap(activityId) {
  var params = {
    url: apiKeys['get.snap'],
    queryKey: [reactQueryKeys.activitySnap, activityId],
    staleTime: Infinity,
    id: activityId
  };
  return params;
}