import useCreateFlowInteraction from '../../../logic/interactions/useCreateFlowInteraction';
import useStore from '../../../logic/zustand';
import CreateFlowModal from './CreateFlow';

const CreateFlow = () => {
  const { isOpenModal, closeModal } = useStore((store) => ({
    isOpenModal: store.modal.isOpen,
    closeModal: store['modal/close'],
  }));

  const { create } = useCreateFlowInteraction();

  const onSubmit = async (value) => {
    await create(value);
  };

  return (
    <CreateFlowModal
      onSubmit={onSubmit}
      isOpenModal={isOpenModal}
      closeModal={closeModal}
    />
  );
};

export default CreateFlow;
