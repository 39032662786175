import { useToast } from '@chakra-ui/react';
import trackingConsts from '../../config/tracking';
import useTracking from '../hooks/useTracking';
import { useUpdateEnvVar } from '../hooks/useUpdateEnvVar';

function useUpdateEnvVarInteraction() {
  const toast = useToast();
  const { track } = useTracking();
  const { updateEnvVar: update, isLoading } = useUpdateEnvVar();
  const updateEnvVar = async (payload) => {
    try {
      const resp = await update(payload);
      toast({
        title: 'Environment variable updated successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top'
      });
      track(trackingConsts.SETTINGS.UPDATED_ENVIRONMENT_VARIABLE, resp)
    } catch (error) {
      const description = error?.response?.data?.message || 'Could not update environment variable'
      toast({
        title: 'Some error occurred',
        description,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top'
      });
      track(trackingConsts.SETTINGS.UPDATED_ENVIRONMENT_VARIABLE, {error: description})
    }
  }

  return { updateEnvVar, isUpdatingEnvVar: isLoading }

}

export default useUpdateEnvVarInteraction
