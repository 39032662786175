import { Button } from '@chakra-ui/react';

interface IBasicButton {
  children: React.ReactNode;
  onClick: () => void;
  variant?: string;
  colorScheme?: string;
  icon?: JSX.Element;
  borderColor?: string;
  bgColor?: string;
}

export default function BasicButton({
  children,
  variant,
  onClick,
  colorScheme,
  icon,
  borderColor,
  bgColor
}: IBasicButton) {
  return (
    <Button
      bgColor={bgColor}
      borderWidth="1px"
      borderColor={borderColor}
      leftIcon={icon}
      colorScheme={colorScheme}
      onClick={onClick}
      variant={variant}
    >
      {children}
    </Button>
  );
}

BasicButton.defaultProps = {
  variant: 'solid',
  colorScheme: 'gray',
  icon: <></>,
  borderColor: 'gray.700',
  bgColor: 'gray.800'
};
