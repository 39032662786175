import {
  Badge,
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
  useColorModeValue,
  Text
} from '@chakra-ui/react';
import { FC, useRef, useState } from 'react';
import TYPES from '../../../config/types';
import { ButtonRadioGroup } from './ButtonRadioGroup';
import useStore from '../../../logic/zustand';
import useTracking from '../../../logic/hooks/useTracking';
import trackingConsts from '../../../config/tracking';

const ConnectToMicroServiceModalStepOne: FC = () => {
  const {
    microservice,
    setSelect,
    openModal,
    isOpenModal,
    closeModal,
    clearWaiting
  } = useStore((store) => ({
    microservice: store.select[TYPES.SELECT.MICROSERVICE],
    setSelect: store.setSelect,
    openModal: store['modal/open'],
    isOpenModal: store.modal.isOpen,
    closeModal: store['modal/close'],
    clearWaiting: store.clearWaiting
  }));

  const getSelectedPackageOption = (_microservice) => {
    const selectedPackageOptions = _microservice?.packageOptions?.find(
      (option) => option.selected
    ) || _microservice?.packageOptions?.[0];

    return selectedPackageOptions;
  };

  const [microservicePackage, setMicroservicePackage] = useState(
    getSelectedPackageOption(microservice)
  );

  const { track } = useTracking();

  const handleOnClick = () => {
    setSelect(TYPES.SELECT.MICROSERVICE_PACKAGE_OPTION, microservicePackage);
    track(trackingConsts.MICROSERVICES.SELECTED_OPTION, {
      name: microservicePackage?.name
    });
    setSelect(TYPES.SELECT.CONNECTION_FEEDBACK, false);
    setSelect(TYPES.SELECT.CONNECTION_CREATE_PROCESSING_MESSAGE, false);
    clearWaiting(TYPES.WAITING.PROCESSING.CREATE_CONNECTION);
    clearWaiting(TYPES.WAITING.PROCESSING.CREATE_TEST_CONNECTION);
    openModal({ type: TYPES.MODALS.CONNECT_TO_MICRO_SERVICE_STEP_TWO });
  };

  const handleChange = (value) => {
    const selectedOption = microservice?.packageOptions?.find(
      (option) => option?.name === value
    );
    setMicroservicePackage(selectedOption);
  };

  const size = useBreakpointValue({ md: '3xl' });
  const isCentered = useBreakpointValue({ md: true });
  const bg = useColorModeValue('white', 'black');

  const initRef = useRef();

  return (
    <Modal
      scrollBehavior="inside"
      size={size}
      initialFocusRef={initRef}
      onClose={closeModal}
      isCentered={isCentered}
      isOpen={isOpenModal}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody
          maxH="max-content"
          rounded="md"
          css={{
            '&::-webkit-scrollbar': {
              display: 'none'
            }
          }}
          bg={bg}
          py="12"
        >
          <Box px={{ base: '2', md: '6' }}>
            <Box textAlign="center" mb="10">
              <Badge px="3" py="1" variant="solid" colorScheme="blue">
                <Text> Step 1</Text>
              </Badge>
              <Heading size="lg" fontWeight="extrabold" mt="6" mb="2">
                Select one of the following actions
              </Heading>
            </Box>
            <Box maxW="2xl" mx="auto">
              <Box>
                {microservice?.packageOptions &&
                  microservice.packageOptions.length > 0 && (
                    <ButtonRadioGroup
                      onChange={(value) => handleChange(value)}
                      defaultValue={
                        microservice.packageOptions?.[0]?.selectedName ||
                        microservice.packageOptions?.[0]?.name
                      }
                      options={microservice.packageOptions.map(
                        (option, index) => ({
                          label: option.name,
                          description: option.description,
                          icon: option.logo,
                          isDisabled: option.status !== 'enabled',
                          value: option?.name
                        })
                      )}
                    />
                  )}
                <Button
                  ref={initRef}
                  onClick={handleOnClick}
                  mt={5}
                  colorScheme="blue"
                  isFullWidth
                >
                  Continue
                </Button>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConnectToMicroServiceModalStepOne;
