import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";
import { apiKeys } from '@buildablecore/src/logic/utils/apiKeys';
import { apiWithToken } from '@buildablecore/src/logic/utils/dispatchRecipe';
import { useMutation, useQueryClient } from 'react-query';
import { reactQueryKeys } from '../queryKeys';

function useUpdateNodeCode() {
  var queryClient = useQueryClient();

  var getKeyForType = function getKeyForType(type) {
    if (type === 'input') {
      return 'update.node.input';
    }

    if (type === 'edit') {
      return 'update.node.exec';
    }

    return 'update.node.sample';
  };

  var mutation = useMutation(function (payload) {
    return apiWithToken({
      url: apiKeys[getKeyForType(payload.key)],
      method: 'POST',
      payload: _defineProperty({
        id: payload.id,
        nodeId: payload.nodeId
      }, payload.key === 'edit' ? 'exec' : payload.key, payload.value)
    });
  }, {
    onSuccess: function onSuccess(_, data) {
      // sync with BE
      queryClient.invalidateQueries([reactQueryKeys.logicFlow, data === null || data === void 0 ? void 0 : data.id]);
    },
    onError: function onError(_, data) {
      return queryClient.invalidateQueries([reactQueryKeys.logicFlow, data === null || data === void 0 ? void 0 : data.id]);
    }
  });
  return {
    updateCode: mutation.mutate
  };
}

export default useUpdateNodeCode;