import React from 'react';

interface Observer {
  root?: any;
  target?: any;
  onIntersect?: any;
  threshold?: any;
  rootMargin?: string;
  enabled?: boolean;
}

export function useIntersectionObserver({
  root, target, onIntersect, threshold = 1.0, rootMargin = '0px', enabled = true, }: Observer) {
  React.useEffect(() => {

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => entry.isIntersecting && onIntersect()),
      {
        root: root && root.current,
        rootMargin,
        threshold,
      }
    );

    const el = target && target.current;

    if (el && enabled) {
      observer.observe(el);
      return () => observer.unobserve(el);
    }

    return null

  }, [target.current, enabled]);
}

