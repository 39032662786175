import dynamic from "next/dynamic";
import { VStack, Text, Box } from "@chakra-ui/react";
import { ImMagicWand } from "react-icons/im";
import useTracking from "../../../logic/hooks/useTracking";
import trackingConsts from "../../../config/tracking";

const CreateRecipeButton = dynamic(() => import('../../shared/CreateRecipeButton'), { ssr: false });

const EmptyState = () => {
    const { track } = useTracking();
    return (
        <VStack spacing={3}>
            <ImMagicWand size={15} />
            <Text>You haven't created a Flow yet.</Text>
            <CreateRecipeButton onClick={() => track(trackingConsts.DASHBOARD_SIDEBAR.CLICKED_CREATE_FLOW)} />
        </VStack>
    );
}

export default EmptyState;