import { useMutation, useQueryClient } from 'react-query';
import { apiWithToken } from '../utils/dispatchRecipe';
import { apiKeys } from '../utils/apiKeys';
import reactQueryKeys from '../queryKeys';

export const useCreateFlow = () => {
  const queryClient = useQueryClient();

  const create = useMutation(
    (payload: {
      name: string;
      description: string;
    }) => apiWithToken({
      url: apiKeys['create.logicFlow'],
      method: 'POST',
      payload
    }),
    {
      onSuccess: (data) => {
        // sync with BE
        queryClient.invalidateQueries([reactQueryKeys.flowList]);
      },
      onError: (data, ...rest) =>
        queryClient.invalidateQueries([reactQueryKeys.flowList]),
    }
  )

return { createFlow: create.mutateAsync, error: create.error, isLoading: create.isLoading }
};
