import DRAWER from '@Builder/constants/drawers';
import MODAL from '@Builder/constants/modals';
import { logger } from '@Builder/utils/Logger';
import { useState } from 'react';

function useOverlay() {
  var _useState = useState(''),
      currentOverlayKey = _useState[0],
      setCurrentOverlayKey = _useState[1];

  function openOverlay(key) {
    if (Object.values(DRAWER).includes(key) || Object.values(MODAL).includes(key)) {
      setCurrentOverlayKey(function () {
        return key;
      });
    } else {
      logger.warn("Trying to open overlay with invalid key: ".concat(key));
    }
  }

  function closeOverlay() {
    setCurrentOverlayKey('');
  }

  function toggleOverlay(key) {
    if (currentOverlayKey === key) {
      closeOverlay();
    } else {
      openOverlay(key);
    }
  }

  return {
    currentOverlayKey: currentOverlayKey,
    openOverlay: openOverlay,
    closeOverlay: closeOverlay,
    toggleOverlay: toggleOverlay
  };
}

export default useOverlay;