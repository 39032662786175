import {
  Box,
  Button,
  Code,
  Divider,
  Flex,
  Grid,
  Heading,
  Link,
  useColorModeValue,
  Wrap,
} from '@chakra-ui/react';
import Markdown from 'markdown-to-jsx';
import { useState } from 'react';
import { useBuildableForm } from '../../form/src';
import { IForm, IObject } from '../../form/src/shared/interface';
import useCreateTestConnectionFlow from '../../../logic/flows/useCreateTestConnection';
import useCreateConnectionFlow from '../../../logic/flows/useCreateConnection';
import useStore from '../../../logic/zustand';
import TYPES from '../../../config/types';
import Notification from './Alert';
import { capitalizeString } from '../../../helpers/utility';
import FormLoader from '../../form/src/shared/FormLoader';

const markdownOptions = {
  overrides: {
    a: {
      component: Link,
    },
    code: {
      component: Code,
      props: {
        padding: '10px',
        borderRadius: '2px',
        marginTop: '10px',
        marginBottom: '10px',
      },
    },
    h1: {
      component: Heading,
      props: {
        level: 1,
        size: 'lg',
        mt: '4px',
        mb: '4px',
      },
    },
    h2: {
      component: Heading,
      props: {
        level: 2,
        size: 'lg',
        mt: '4px',
        mb: '4px',
      },
    },
    h3: {
      component: Heading,
      props: {
        level: 3,
        size: 'md',
        mt: '4px',
        mb: '4px',
      },
    },
    h4: {
      component: Heading,
      props: {
        level: 4,
        size: 'md',
        mt: '4px',
        mb: '4px',
      },
    },
    h5: {
      component: Heading,
      props: {
        level: 5,
        size: 'md',
        mt: '4px',
        mb: '4px',
      },
    },
  },
};

interface Recipes {
  test: string;
  create: string;
  getStatus: string;
  append?: IObject;
}

interface IProps {
  formData: IForm[];
  docs: string;
  recipes: Recipes;
  testConnectionDisabled: boolean;
  successfulCreationInitiation: Record<string, unknown>;
  connectButtonText: string;
  connectButtonLoadingText: string;
}

const defaultWrapperStyle = {
  columns: 1,
  spacingX: '40px',
  spacingY: '20px',
};

const ConnectForm = (props: IProps) => {
  const { formData, docs, recipes, testConnectionDisabled, successfulCreationInitiation, connectButtonText, connectButtonLoadingText } = props;

  const [type, setType] = useState('test');

  const createTestConnection = useCreateTestConnectionFlow({
    triggerId: recipes.test,
  });

  const createConnection = useCreateConnectionFlow({
    triggerIdCreate: recipes.create,
    triggerIdGetStatus: recipes.getStatus,
    successfulCreationInitiation
  });

  const {
    isProcessingTestConnection,
    isProcessingCreateConnection,
    connectionFeedback,
    processingMessage,
  } = useStore((store) => ({
    isProcessingTestConnection:
      store.waiting[TYPES.WAITING.PROCESSING.CREATE_TEST_CONNECTION],
    isProcessingCreateConnection:
      store.waiting[TYPES.WAITING.PROCESSING.CREATE_CONNECTION],
    connectionFeedback: store.select[TYPES.SELECT.CONNECTION_FEEDBACK],
    processingMessage:
      store.select[TYPES.SELECT.CONNECTION_CREATE_PROCESSING_MESSAGE],
  }));

  const handleOnSubmit = (values) => {
    const payload = {
      formData: values,
      ...recipes.append,
    };
    if (type === 'test') {
      createTestConnection(payload);
    } else if (type === 'connect') {
      createConnection(payload);
    }
  };

  const { Form, isValid, handleSubmit, onSubmit, getValues } = useBuildableForm(
    {
      dynamicFormData: formData,
      defaultWrapperStyle,
      showButton: false,
      handleOnSubmit,
    }
  );

  const borderColor = useColorModeValue('gray.100', 'gray.700');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box pt={2} mt={5} borderColor={borderColor}>
        <Grid templateColumns={{ base: '1fr', md: '55% 5% 40%' }}>
          <Box>
            <Wrap mb={5} display={{ base: 'block', md: 'none' }}>
              <Markdown
                options={markdownOptions}
                // this is an antipattern to pass `children` as a prop. TODO: abstract or replace. 
                // eslint-disable-next-line react/no-children-prop
                children={docs}
              />
            </Wrap>
            {isProcessingCreateConnection || isProcessingTestConnection ? (
              <FormLoader dynamicFormData={formData} />
            ) : (
              <Form />
            )}
          </Box>
          <Box />
          <Wrap display={{ base: 'none', md: 'block' }}>
            <Markdown
              options={markdownOptions}
              // this is an antipattern to pass `children` as a prop. TODO: abstract or replace. 
              // eslint-disable-next-line react/no-children-prop
              children={docs}
            />
          </Wrap>
        </Grid>
        <Divider my={5} borderColor={borderColor} />
        {connectionFeedback && <Notification feedback={connectionFeedback} />}
        <Flex mt={5} justify="space-between" align="center">
          <Button
            onClick={() => setType('test')}
            disabled={
              isProcessingTestConnection ||
              isProcessingCreateConnection ||
              testConnectionDisabled
            }
            type="submit"
            isLoading={isProcessingTestConnection}
          >
            Test connection
          </Button>
          <Button
            onClick={() => setType('connect')}
            isLoading={isProcessingCreateConnection}
            disabled={
              isProcessingCreateConnection ||
              isProcessingTestConnection
            }
            type="submit"
            colorScheme="green"
            loadingText={
              connectButtonLoadingText || (processingMessage && `${capitalizeString(processingMessage)} ...`)
            }
          >
            {connectButtonText || "Connect"}
          </Button>
        </Flex>
      </Box>
    </form>
  );
};

export default ConnectForm;
