import { Button, Link as ChakraLink } from '@chakra-ui/react';
import NextLink from 'next/link';

interface LinkProps {
  href: string;
  children: React.ReactNode;
  secondary?: boolean;
  button?: boolean;
  variant?: "solid" | "ghost" | "outline"
}

export default function Link({ href, children, secondary, button, variant }: LinkProps) {
  const color = secondary ? 'blue.200' : 'white';
  const onHover = secondary ? {textDecoration: "underline"} : {textDecoration: "none"};
  const isExternal = href.startsWith('http');

  const innerLink =
    button ? (
      <Button target={isExternal ? "_blank" : "_self"} justifyContent="start" variant={variant} as="a">
        {children}
      </Button>
    ) : (
      <ChakraLink _hover={onHover} isExternal={isExternal} color={color}>{children}</ChakraLink>
    );

  return (
    <NextLink passHref href={href}>
      {innerLink}
    </NextLink>
  );
}

Link.defaultProps = {
  secondary: false,
  button: false,
  variant: "solid"
};
