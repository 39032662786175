import { useMutation, useQueryClient } from 'react-query';
import { apiWithToken } from '../utils/dispatchRecipe';
import { apiKeys } from '../utils/apiKeys';
import reactQueryKeys from '../queryKeys';

export const useCreateEnvVar = () => {
  const queryClient = useQueryClient();
  const create = useMutation((payload: {
    name: string;
    development: string;
    production: string;
  }) => apiWithToken({
    url: apiKeys['create.environment.variables'],
    method: 'POST',
    payload
  }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([reactQueryKeys.envVarsList]);
      }
    }
  );
  return { createEnvVar: create.mutateAsync, isLoading: create.isLoading };

};
